import { fetchLocationData } from "@/own/api/get-location-data";
import {
  findObjectInArrayWithFormatting,
  findObjectInArray,
} from "@/own/libs/helper";

/**
 * Serializes an object value and then finds an object in an array of objects based on a specified property value.
 *
 * @param {string | number | object} value The value to search for in the array of objects.
 * @param propertyToCheck
 * @param objectsToSearch
 * @returns {object | null} The matching object or null if no matching object is found.
 */
function serializeValueAndFindObjectInArray(
  value,
  propertyToCheck,
  objectsToSearch
) {
  if (!value || !objectsToSearch || objectsToSearch.length === 0) {
    return null;
  }

  let searchValue = value;

  if (typeof value === "object" && value !== null) {
    searchValue = value[propertyToCheck];
  }
  return findObjectInArrayWithFormatting(
    searchValue,
    propertyToCheck,
    objectsToSearch
  );
}

/**
 * Handles the change in selected country and updates the corresponding state information.
 *
 * @param {string | number} val The selected country ID or name.
 * @param {boolean} safe Whether to reset address and server data when switching countries (default: true).
 */
async function handleCountryValueChange(
  val,
  safe = true,
  dataName = "address",
  serverDataName = "serverData"
) {
  if (!val) return;

  const newStates = await fetchLocationData(val, "state");
  if (newStates) {
    this[serverDataName].states = newStates;
    // await handleStateValueChange(this.address.state.title, true, false);
  } else {
    return null;
  }

  if (!safe) {
    this[dataName].state = null;
  }
  this[serverDataName].cities = null;
  this[serverDataName].areas = null;

  // used to trigger computed values udpate
  this[serverDataName] = { ...this[serverDataName] };
}
/**
 * Handles the change in selected state and updates the corresponding city and area information.
 *
 * @param {string | number | object} val The selected state ID, name, or object.
 * @param {boolean} safe Whether to reset city and areas when switching states (default: true).
 * @param {boolean} is_id Whether the provided state value is an ID (true) or name/object (false) (default: false).
 */
async function handleStateValueChange(
  value,
  safe = true,
  is_id = false,
  dataName = "address",
  serverDataName = "serverData"
) {
  if (!value) return;

  const propertyName = is_id ? "id" : "title";
  let currentState = null;

  if (is_id) {
    currentState = findObjectInArray(
      value,
      propertyName,
      this[serverDataName].states
    );
  } else {
    currentState = serializeValueAndFindObjectInArray(
      value,
      propertyName,
      this[serverDataName].states
    );
  }

  if (!currentState) {
    // TODO: add swal message can not get current state
    return;
  }

  this[dataName].state = currentState;

  const newCities = await fetchLocationData(currentState.id, "city");
  if (newCities) {
    this[serverDataName].cities = newCities;
    // await handleCityValueChange(this.address.city.title, true, false);
  } else {
    return;
  }

  if (!safe) {
    this[dataName].city = null;
  }
  this[serverDataName].areas = null;
  // used to trigger computed values udpate
  this[serverDataName] = { ...this[serverDataName] };
}
/**
 * Handles the change in selected city and updates the corresponding area information.
 *
 * @param {string | number | object} val The selected city ID, name, or object.
 * @param {boolean} safe Whether to reset areas when switching cities (default: true).
 * @param {boolean} is_id Whether the provided city value is an ID (true) or name/object (false) (default: false).
 */
async function handleCityValueChange(
  value,
  safe = true,
  is_id = false,
  dataName = "address",
  serverDataName = "serverData"
) {
  if (!value) return;

  const propertyName = is_id ? "id" : "title";
  let currentCity = null;

  if (is_id) {
    currentCity = findObjectInArray(
      value,
      propertyName,
      this[serverDataName].cities
    );
  } else {
    currentCity = serializeValueAndFindObjectInArray(
      value,
      propertyName,
      this[serverDataName].cities
    );
  }

  if (!currentCity) {
    return;
  }

  this[dataName].city = currentCity;

  const newAreas = await fetchLocationData(currentCity.id, "area");

  if (newAreas) {
    this[serverDataName].areas = newAreas;
  } else {
    return;
  }

  if (!safe) {
    this[dataName].area = null;
  }
  // used to trigger computed values udpate
  this[serverDataName] = { ...this[serverDataName] };
}

export {
  handleCountryValueChange,
  handleStateValueChange,
  handleCityValueChange,
};
