import ApiService from "@/core/services/api.service";

/**
 * Converts a nested object into a FormData instance, recursively handling arrays and nested objects.
 *
 * @param {FormData} formData The FormData instance to which the key-value pairs should be appended.
 * @param {object} obj The object to be converted to FormData.
 * @param {string} parentKey (optional) A prefix to be prepended to the keys of the object's properties.
 */
function buildFormData(formData, obj, parentKey = "") {
  if (Array.isArray(obj)) {
    // Handle arrays
    obj.forEach((element, index) => {
      buildFormData(formData, element, `${parentKey}[${index}]`);
    });
  } else if (typeof obj === "object" && !(obj instanceof File)) {
    // Handle non-File objects
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        // Skip null or undefined values
        return;
      }
      const newParentKey = parentKey ? `${parentKey}[${key}]` : key;
      buildFormData(formData, obj[key], newParentKey);
    });
  } else {
    // Handle other data types
    if (obj == null) {
      return;
    }

    const value =
      typeof obj === "number" || typeof obj === "boolean"
        ? obj.toString()
        : obj;
    formData.append(parentKey, value);
  }
}

/**
 * Fetches data for the edit form based on the provided data and URL.
 *
 * @param {object} formData The form data to send to the server.
 * @param {string} apiEndpoint The URL of the API endpoint to fetch data from.
 * @returns {object | null} The response data object or null if an error occurs.
 */
const fetchEditFormData = async (formData, apiEndpoint) => {
  if (!formData || !apiEndpoint) return null;

  let responseData;

  try {
    const response = await ApiService.post(apiEndpoint, formData);
    responseData = response.data;
  } catch (error) {
    // console.error(error);
    return null;
  }

  return responseData;
};

/**
 * Submits edited form data to the specified API endpoint.
 *
 * @param {object} formData The edited form data to submit.
 * @param {string} apiEndpoint The URL of the API endpoint to submit data to.
 * @returns {object | null} The response data object or null if an error occurs.
 */
const submitEditFormData = async (formData, apiEndpoint) => {
  if (!formData || !apiEndpoint) return null;

  let responseData;
  const data = new FormData();
  // console.log(formData, apiEndpoint);
  buildFormData(data, formData);
  // console.log(formData);

  try {
    const response = await ApiService.post(apiEndpoint, data);
    responseData = response.data;
  } catch (error) {
    // console.error(error);
    return null;
  }

  return responseData;
};

export { fetchEditFormData, submitEditFormData };
