<template>
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    persistent
    width="500"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Order Update</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mt-4">
        <v-row class="pa-0">
          <v-col cols="12">
            <v-text-field
              label="Number Of Box"
              v-model="formData.numberOfBoxes"
              type="number"
              dense
              outlined
              @change="() => validateMinValue('numberOfBoxes', 0)"
              min="0"
            />
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click.prevent="clearFields"
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
          >
            Clear
          </button>
          <button
            @click.prevent="submitStatusUpdate"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "NumberOfBoxModal",
  props: {
    refresher: { required: true, type: Function },
  },
  mixins: [fieldValueValidation],
  data: () => ({
    modalState: false,
    formData: {
      ordersId: null,
      numberOfBoxes: null,
    },
  }),
  computed: {},
  methods: {
    toggleModal(data) {
      // console.log(data);
      if (!this.modalState) {
        this.formData.ordersId = data.order.id;
        this.formData.numberOfBoxes = data.order.numberOfBoxes;
      } else this.clearFields();
      this.modalState = !this.modalState;
    },

    submitStatusUpdate() {
      this.$store.commit(SET_PAGE_LOADING, true);

      ApiService.post(`/fulfillment/orders/update_order_client_save`, {
        ...this.formData,
      })
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "Order is updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
          this.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        ordersId: null,
        numberOfBoxes: null,
      };
    },
  },
};
</script>
