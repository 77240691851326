<template>
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    persistent
    width="500"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Order Status Update</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7 mt-8">
        <v-row>
          <v-col md="12" class="py-0">
            <v-select
              dense
              v-model="status"
              outlined
              clearable
              label="Status"
              item-text="text"
              item-value="index"
              :items="statusOptions"
              :error-messages="statusErrorState"
              @blur="checkStatusError"
              @input="checkStatusError"
            ></v-select>
          </v-col>
          <v-col md="12" class="py-0">
            <v-datetime-picker
              label="Date time"
              v-model="date"
              :textFieldProps="{
                outlined: true,
                dense: true,
                'append-icon': 'mdi-calendar',
                'error-messages': dateErrorState,
              }"
              :datePickerProps="{}"
              :timePickerProps="{}"
              @input="checkDateError"
            >
            </v-datetime-picker>
          </v-col>
          <v-col md="12" class="py-0">
            <v-textarea
              v-model="description"
              dense
              outlined
              label="Description"
              no-resize
            />
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click.prevent="clearFields"
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
          >
            Clear
          </button>
          <button
            @click.prevent="submitStatusUpdate"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "StatusUpdateModal",
  props: {
    statusDetails: { type: Object, require: true },
    refresher: { required: true, type: Function },
  },
  data: () => ({
    modalState: false,
    statusOptions: [],
    status: null,
    statusErrorState: null,
    dateErrorState: null,
    description: null,
    date: null,
  }),
  methods: {
    toggleModal: function () {
      this.status = null;
      this.description = null;
      this.statusOptions = this.statusDetails.statuses;
      this.modalState = !this.modalState;
    },

    checkStatusError: function () {
      this.statusErrorState = !this.status ? "This field is required" : null;
    },

    checkDateError: function () {
      this.dateErrorState = !this.date ? "This field is required" : null;
    },

    submitStatusUpdate: function () {
      this.checkStatusError();
      this.checkDateError();

      if (!this.statusErrorState && !this.dateErrorState) {
        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          id: this.statusDetails.id,
          status_id: this.status,
          description: this.description,
        };

        // set datetime formate
        if (this.date && this.date !== "") {
          payload.date = this.dateFormatter(this.date);
        }
        new Promise((resolve) => {
          ApiService.post(
            `/fulfillment/orders/update_order_status_save`,
            payload
          ).then(() => {
            resolve();
            this.$store.commit(SET_PAGE_LOADING, false);
            Swal.fire({
              title: "Updated",
              text: "Order status updated",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.clearFields();
            this.refresher();
            this.modalState = false;
          });
        });
      }
    },
    clearFields: function () {
      this.status = null;
      this.statusErrorState = null;
      this.date = null;
      this.dateErrorState = null;
      this.description = null;
    },

    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
  },
};
</script>
