import ApiService from "@/core/services/api.service";

/**
 * Maps each location type to its parent type.
 * @type {Object.<string, string>}
 */
const locationTypeToParentType = {
  state: "country",
  city: "state",
  area: "city",
};

/**
 * Maps each location type to its endpoint.
 * @type {Object.<string, string>}
 */
const locationTypeToEndpoint = {
  state: "states",
  city: "cities",
  area: "areas",
};

/**
 * Constructs the URL for searching a specific location type.
 * @param {string} locationType - The type of location (state, city, or area).
 * @returns {string} - The constructed URL for searching the location type.
 */
function constructLocationUrl(locationType) {
  if (!locationType) return null;
  return `/address/${locationTypeToEndpoint[locationType]}/search`;
}

/**
 * Fetches location data based on the provided location ID and type.
 * @param {string} locationId - The ID of the location.
 * @param {string} locationType - The type of location (state, city, or area).
 * @returns {Promise<object|null>} - A promise that resolves to the fetched location data, or null if an error occurs.
 */
async function fetchLocationData(locationId, locationType) {
  try {
    const response = await ApiService.post(constructLocationUrl(locationType), {
      [locationTypeToParentType[locationType]]: locationId,
    });
    return response.data[locationTypeToEndpoint[locationType]];
  } catch (error) {
    // console.error(error);
    return null;
  }
}

export { fetchLocationData };
